<template>

  <div class="Message-wrap">

   <div class="panel">
      <div @click="$router.push({name:'Marketing',query:{category_id:item.id,category_name:item.category_name}})" v-for="(item,index) in smsTypeList" :key="index">
        <img v-if="!item.node_id" :src="item.img">
        <p>{{item.category_name}}</p>
        <div v-if="item.node_id" class="operateBox">
          <van-button v-on:click.stop="edit(item)" plain type="primary">编辑</van-button>
          <van-button v-on:click.stop="categoryDelete(item.id)" plain type="info">删除</van-button>
        </div>
      </div>
      <div class="add" @click="show=true">
        <img style="margin: 0" src="@/assets/images/add.png">
      </div>
      <div style="height:0"></div>
      <div style="height:0"></div>
    </div>

    <van-popup v-model="show">
      <div class="smsEdit">
        <h3>{{editParam.id?'编辑':'添加分类'}}</h3>
        <van-icon @click="show=false" name="cross" size="18" color="#C8C8C8"/>
        <van-field v-model="editParam.category_name" maxlength="10" placeholder="请输入分类名称" />
        <div class="operationBox">
          <van-button plain type="primary" @click="show=false">取消</van-button>
          <van-button v-if="editParam.id" type="info" @click="categoryEdit">确定</van-button>
          <van-button v-else type="info" @click="categoryAdd">确定</van-button>
        </div>
      </div>
    </van-popup>

  </div>

</template>

<script>
import table from '@/api/table'
export default {
  name: 'Message',
  mixins: [],
  data() {
    return {
      show:false,
      smsTypeList:[],
      editParam:{category_name:''}
    }
  },
  watch:{
    show(newVal){
      var _this=this
      setTimeout(function (){if(!newVal) _this.editParam={category_name:''}},500)
    }
  },
  mounted() {
    this.categoryList()
  },
  methods: {
    // 获取短信类型
    categoryList(){
      let _this=this
      return new Promise(function(resolve, reject) {
        table.categoryList().then(response=>{
          let list=response.data.list.map(item=>{
            switch(item.id){
              case 1:
                item.img=require('@/assets/images/marketing.png')
                break;
              case 2:
                item.img=require('@/assets/images/promotion.png')
                break;
              case 3:
                item.img=require('@/assets/images/Greeting.png')
                break;
              case 4:
                item.img=require('@/assets/images/Blessing.png')
                break;
              case 5:
                item.img=require('@/assets/images/Notice.png')
                break;
              case 6:
                item.img=require('@/assets/images/Care.png')
                break;
            }
            return item
          })
          _this.smsTypeList=list
          resolve(list)
        })
      })
    },
    edit(val){
      let {category_name,id}=val
      this.editParam={category_name,id}
      this.show=true
    },
    categoryEdit(){
      let {category_name,id}=this.editParam
      table.categoryEdit({category_name,id}).then(response=>{
        if(response.data.result){
          this.categoryList().then(res=>{
            this.show=false
          })
        }
      })
    },
    categoryAdd(){
      table.categoryAdd({category_name:this.editParam.category_name}).then(response=>{
        if(response.data.result){
          this.categoryList().then(res=>{
            this.show=false
          })
        }
      })
    },
    categoryDelete(id){
      table.categoryDelete({id}).then(response=>{
        if(response.data.result){
          this.categoryList()
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
  .Message-wrap {
    /deep/ .van-popup{background: transparent;}
    .van-cell::after {border-bottom:none}
    .smsEdit{
      position: relative;
      padding: 1px 50px;
      background: #fff;
      border-radius: 10px;
      .van-icon-cross{
        position: absolute;
        top: 25px;
        right: 25px;
      }
      h3{
        text-align: center;
        font-size: 32px;
        font-weight: bold;
        color: #000000;
        margin: 40px 0 28px 0;
      }
      /deep/.van-field{
        width: 446px;
        line-height: 72px;
        padding: 0 23px;
        background: #ECECEE;
        border-radius: 10px;
        margin-bottom: 32px;
        input{
          background:#ECECEE !important
        }
      }
      .operationBox{
        padding-bottom: 40px;
        display: flex;
        justify-content: space-between;
        >button{
          width: 200px;
          height: 70px;
          font-size: 28px;
          font-weight: 500;
          border-radius: 8px
        }
        >button:nth-child(1){
          color: #666666;
          border: 2px solid #DCDCDC;
        }
        >button:nth-child(2){color: #fff}
      }
    }
    .panel {
      padding-top: 50px;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      >div{
        width: 200px;
        height: 180px;
        background-color: #fff;
        margin-bottom:33px;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img{
          margin-bottom: 27px
        }
        p{
          display: inline-block !important;
          font-size: 28px;
          color: #1C1E1C;
          text-align: center;
        }
        .operateBox{
          margin-top: 20px;
          display: flex;
          button:nth-child(1){margin-right: 8px}
          button{
            background: #FFFFFF;
            border: 2px solid #E5E5E5;
            border-radius: 10px;
            font-size: 22px;
            font-weight: 500;
            color: #999999;
            height: 40px;
            padding: 0 19px;
          }
        }
      }
    }
  }
</style>
